import { Injectable, effect, signal } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http';
import { map, catchError, switchMap, expand, delay, filter } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, throwError } from 'rxjs';
import {
    OnboardingBankServices,
    ElsaContractType,
    ElsaCustomerPartial,
    OnboardingState,
    TaxMandatoryType,
    OnboardingStep,
    ONBOARDING_STEPS,
    ContractType,
    ElsaCompanySettingsDto,
    CompanyCreatedReceipt,
} from '@app/models';
import { BankAccount, Company } from '@uni-entities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { theme, THEMES } from 'src/themes/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { ModulusService } from '@app/services/common/modulusService';
import { HttpClient } from '@angular/common/http';
import { get } from 'lodash-es';
import { AuthService } from '@app/authService';
import { ErrorService } from '@app/services/common/errorService';
import { InitService } from '@app/services/common/initService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { environment } from 'src/environments/environment';
import { CompanyBankAccountDto } from '@app/services/accounting/bankAccountService';

const ONBOARDING_STEPS_STORAGE_KEY = 'onboarding-steps';
const ONBOARDING_STATE_STORAGE_KEY = 'onboarding-state';
const ONBOARDING_BANK_ACCOUNTS_STORAGE_KEY = 'onboarding-bank-accounts-state';
const SELECT_COMPANY_STATE_STORAGE_KEY = 'select-company-state';

type SelectCompanyState = {
    viewMode: number;
    endUserAgreements: any[];
    selectedEndUserAgreement: any;
    missingOrgNumber: boolean;
    brregLocked: boolean;
};
type SelectCompanyStateKeys =
    | 'viewMode'
    | 'endUserAgreements'
    | 'selectedEndUserAgreement'
    | 'missingOrgNumber'
    | 'brregLocked';

type BankAccountsState = {
    bankAccounts: BankAccount[];
    companyBankAccounts: BankAccount[];
    selectedDefaultBankAccount: BankAccount;
    hasGuessedLedgerNumbers: boolean;
};
type BankAccountsStateKeys =
    | 'bankAccounts'
    | 'companyBankAccounts'
    | 'selectedDefaultBankAccount'
    | 'hasGuessedLedgerNumbers';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;

    state = signal<OnboardingState>(null);

    selectCompanyState = signal<SelectCompanyState>({
        viewMode: 1,
        endUserAgreements: [],
        selectedEndUserAgreement: null,
        missingOrgNumber: false,
        brregLocked: false,
    });

    bankAccountsState = signal<BankAccountsState>({
        bankAccounts: [],
        companyBankAccounts: [],
        selectedDefaultBankAccount: null,
        hasGuessedLedgerNumbers: false,
    });

    theme = theme.theme;
    themes = THEMES;

    steps: OnboardingStep[] = [];
    currentStep: OnboardingStep;
    currentStepIndex: number;
    existingCompanies: Company[] = [];
    isExistingCustomer: boolean = JSON.parse(sessionStorage.getItem('INIT_EXISTING_CUSTOMER')) ?? false;
    isDemoFlow = false;

    fetchingContractTypes = false;
    busy = false;
    busyCreatingCompany = false;
    createdCompany: Company;
    orgnrFromParams = '';
    isProspect = false;
    campaignCode: string;

    contractTypes: ElsaContractType[] = [];
    selectedContractType: number;

    constructor(
        private uniHttp: UniHttp,
        private toastService: ToastService,
        private storage: BrowserStorageService,
        private elsaContractService: ElsaContractService,
        private router: Router,
        private route: ActivatedRoute,
        private modulusService: ModulusService,
        private http: HttpClient,
        private authService: AuthService,
        private errorService: ErrorService,
        private initService: InitService,
    ) {
        effect(() => {
            if (this.state()) {
                this.storage.setSessionItem(ONBOARDING_STATE_STORAGE_KEY, this.state());
            }

            if (this.selectCompanyState()) {
                this.storage.setSessionItem(SELECT_COMPANY_STATE_STORAGE_KEY, this.selectCompanyState());
            }
        });

        effect(() => {
            if (this.bankAccountsState()) {
                this.storage.setSessionItem(ONBOARDING_BANK_ACCOUNTS_STORAGE_KEY, this.bankAccountsState());
            }
        });

        const storageState = this.storage.getSessionItem(ONBOARDING_STATE_STORAGE_KEY);
        if (storageState) {
            this.set(storageState);
        }

        const selectCompanyState = this.storage.getSessionItem(SELECT_COMPANY_STATE_STORAGE_KEY);
        if (selectCompanyState) {
            this.setSelectCompanyState(selectCompanyState);
        }

        const bankAccountsState = this.storage.getSessionItem(ONBOARDING_BANK_ACCOUNTS_STORAGE_KEY);
        if (bankAccountsState) {
            this.setBankAccountsState(bankAccountsState);
        }
    }

    set(state: OnboardingState) {
        this.state.set(state);
    }

    // make it private to prevent typos?
    setKey<K extends keyof OnboardingState>(key: K, value?: any) {
        this.state.update((s) => ({ ...s, [key]: value }));
    }

    setCustomer(value?: ElsaCustomerPartial) {
        this.setKey('Customer', value);
    }

    setCompanySettings(value?: ElsaCompanySettingsDto) {
        this.setKey('CompanySettings', value);
    }

    setBankServices(value?: OnboardingBankServices) {
        this.setKey('BankServices', value);
    }

    setExtras(value?: any) {
        this.setKey('Extras', value);
    }

    setDemoCompanyName(value?: string) {
        this.setKey('DemoCompanyName', value);
    }

    setDefaultBankAccount(value?: BankAccount) {
        this.updateBankAccountsState('selectedDefaultBankAccount', value);
    }

    setBankAccounts(value?: BankAccount[]) {
        this.setCompanySettings({
            ...this.state().CompanySettings,
            BankAccounts: value,
        });
    }

    setTaxMandatoryType(value?: TaxMandatoryType) {
        this.setCompanySettings({
            ...this.state().CompanySettings,
            TaxMandatoryType: value,
        });
    }

    setSelectCompanyState(state: SelectCompanyState) {
        this.selectCompanyState.set(state);
    }

    updateSelectCompanyState(key: SelectCompanyStateKeys, value: any) {
        this.selectCompanyState.update((s) => ({ ...s, [key]: value }));
    }

    setBankAccountsState(state: BankAccountsState) {
        this.bankAccountsState.set(state);
    }

    updateBankAccountsState(key: BankAccountsStateKeys, value: any) {
        this.bankAccountsState.update((s) => ({ ...s, [key]: value }));
    }

    clearBankAccounts() {
        if (!this.bankAccountsState()) return;
        this.bankAccountsState().bankAccounts = [];
        this.bankAccountsState().companyBankAccounts = [];
        this.bankAccountsState().hasGuessedLedgerNumbers = false;
        this.bankAccountsState().selectedDefaultBankAccount = null;
        this.setBankAccountsState({ ...this.bankAccountsState() });
        this.storage.removeSessionItem('HAS_VISITED_BANK_ACCOUNTS');
    }

    clearSessionStorage() {
        this.storage.removeSessionItem(ONBOARDING_STATE_STORAGE_KEY);
        this.storage.removeSessionItem(ONBOARDING_STEPS_STORAGE_KEY);
        this.storage.removeSessionItem(ONBOARDING_BANK_ACCOUNTS_STORAGE_KEY);
        this.storage.removeSessionItem(SELECT_COMPANY_STATE_STORAGE_KEY);
        this.storage.removeSessionItem('INIT_EXISTING_CUSTOMER');
        this.storage.removeSessionItem('HAS_ASKED_EXISTING_CUSTOMER');
        this.storage.removeSessionItem('HAS_VISITED_BANK_ACCOUNTS');
        this.orgnrFromParams = null;
        this.isDemoFlow = false;
    }

    getContractTypes() {
        // executes twice in a row if step is missing from queryparams, lazy fix
        if (this.fetchingContractTypes) return;
        this.fetchingContractTypes = true;
        this.elsaContractService.getOnboardingContractTypes().subscribe({
            next: (ct) => {
                this.contractTypes = ct;
                this.checkHideSelectContractTypeStep();
                this.fetchingContractTypes = false;
            },
            error: () => (this.fetchingContractTypes = false),
        });
    }

    checkHideSelectContractTypeStep() {
        if (ContractType[this.selectedContractType] === undefined) return;

        const isOnboardingType = this.contractTypes.some((ct) => ct.ContractType === this.selectedContractType);

        const selectContractTypeStepIdx = this.steps.findIndex(
            (s) => s.stepType === ONBOARDING_STEPS.SELECT_CONTRACTTYPE,
        );
        const stepAfter = this.steps[selectContractTypeStepIdx + 1];
        if (!stepAfter) return;
        // hide if not onboarding type
        stepAfter.hideGoBackButton = !isOnboardingType;
        if (this.currentStep.stepType === ONBOARDING_STEPS.SELECT_CONTRACTTYPE) {
            this.goNext();
        }
    }

    setSelectedContractType(contractType: number) {
        if (ContractType[contractType] === undefined) {
            console.log('invalid contracttype', contractType);
            return;
        }
        this.selectedContractType = contractType;
        this.setStepIsValidByType(ONBOARDING_STEPS.SELECT_CONTRACTTYPE, true);

        if (!this.contractTypes.length) {
            this.getContractTypes();
        } else {
            this.checkHideSelectContractTypeStep();
        }
    }

    initSelectCompanyStep(): Promise<boolean> {
        this.busy = true;

        return new Promise((resolve, reject) => {
            const handleOrgnoParam = () => {
                if (this.state()?.Customer?.Name) {
                    this.busy = false;
                    resolve(true);
                    return;
                }
                this.searchBrreg(this.orgnrFromParams).subscribe({
                    next: (companies) => {
                        if (companies && companies[0]) {
                            this.onBrregCompanySelected(companies && companies[0], false);
                            this.setCurrentStepIsValid(true);
                            this.updateSelectCompanyState('viewMode', 2);
                            this.updateSelectCompanyState('brregLocked', true);
                        }
                        this.busy = false;
                        resolve(true);
                    },
                    error: () => {
                        this.busy = false;
                        resolve(true);
                    },
                });
            };

            this.initService.getEndUserAgreements().subscribe({
                next: (agreements: any[]) => {
                    let filteredAgreements =
                        agreements?.filter((agreement) => agreement.OrganizationNumber?.length === 9) || [];

                    if (theme.theme === THEMES.EIKA) {
                        filteredAgreements = filteredAgreements.filter((agreement) =>
                            this.authService.publicSettings.Departments.some(
                                (dep) => dep.Identifier === agreement.BankIdentifier,
                            ),
                        );
                    }

                    this.updateSelectCompanyState('endUserAgreements', filteredAgreements || []);
                    if (!filteredAgreements?.length) {
                        this.updateSelectCompanyState('viewMode', 2);
                    }

                    if (this.orgnrFromParams) {
                        handleOrgnoParam();
                    } else {
                        this.busy = false;
                        this.updateSelectCompanyState('brregLocked', false);
                        resolve(true);
                    }
                },
                error: () => {
                    this.updateSelectCompanyState('endUserAgreements', []);
                    this.updateSelectCompanyState('viewMode', 2);
                    this.clearBankAccounts();
                    // reset more stuff?

                    if (this.orgnrFromParams) {
                        handleOrgnoParam();
                    } else {
                        this.busy = false;
                        this.updateSelectCompanyState('brregLocked', false);
                        resolve(true);
                    }
                },
            });
        });
    }

    getExternalBankAccounts() {
        this.busy = true;

        // NB! need to purge bankaccounts from storage whenever the user selects a different company

        if (this.bankAccountsState()?.bankAccounts?.length) {
            if (!this.bankAccountsState().hasGuessedLedgerNumbers && theme.theme === THEMES.SR) {
                this.guessLedgerAccountNumber();
            }
            this.busy = false;
            return;
        }

        // for testing, orgnr with accounts with eika test bankid: '954278271'
        this.initService.getExternalBankAccounts(this.state().Customer.OrgNumber).subscribe({
            next: (bankAccounts: any[]) => {
                this.bankAccountsState().bankAccounts = bankAccounts;
                this.bankAccountsState().companyBankAccounts = this.filterCompanyBankAccounts(bankAccounts);
                this.setBankAccountsState({ ...this.bankAccountsState() });

                if (this.bankAccountsState().companyBankAccounts.length === 1) {
                    this.setDefaultBankAccount(this.bankAccountsState().companyBankAccounts[0]);
                }
                if (theme.theme === THEMES.SR) {
                    this.guessLedgerAccountNumber();
                }
                this.busy = false;
            },
            error: () => {
                this.busy = false;
            },
        });
    }

    filterCompanyBankAccounts(accounts: BankAccount[]): BankAccount[] {
        if (!accounts?.length) return [];
        return accounts.filter((acc) => acc.BankAccountType === 'company');
    }

    guessLedgerAccountNumber() {
        const selectedStandardAccountNumber = this.bankAccountsState()?.selectedDefaultBankAccount?.AccountNumber;
        this.bankAccountsState()
            .bankAccounts.filter((acc) => acc.AccountNumber === selectedStandardAccountNumber)
            .map((acc) => (acc.Account.AccountNumber = 1920));

        this.bankAccountsState()
            .bankAccounts.filter(
                (acc) => acc.BankAccountType === 'company' && acc.AccountNumber !== selectedStandardAccountNumber,
            )
            .map((acc, i) => {
                // map 1921 to 1929
                if (i > 8) return;
                acc.Account.AccountNumber = 1921 + i;
                return acc;
            });

        this.bankAccountsState()
            .bankAccounts.filter((acc) => acc.BankAccountType === 'tax')
            .map((acc, i) => {
                // map 1950 to 1959
                if (i > 9) return;
                acc.Account.AccountNumber = 1950 + i;
                return acc;
            });

        this.bankAccountsState().hasGuessedLedgerNumbers = true;
        this.setBankAccountsState({ ...this.bankAccountsState() });
    }

    createDemoCustomer(payload) {
        this.busyCreatingCompany = true;

        return this.uniHttp.asPOST().usingElsaDomain().withEndPoint(`/api/customers`).withBody(payload).send();
    }

    private setPayload(): string {
        if (!this.state().Customer) {
            return 'State.Customer is null';
        }

        const customer = this.state().Customer;
        if (!customer.Contracts[0]) {
            return 'State.Customer.Contracts is null';
        }

        if (!customer.Contracts[0].CompanyLicenses[0]) {
            return 'State.Customer.Contracts.CompanyLicenses is null';
        }

        customer.Contracts[0].ContractType = this.selectedContractType;

        if (this.campaignCode) {
            customer.Contracts[0].CampaignCode = this.campaignCode;
        }

        // some environments allow editing name and orgnr, make sure they always match
        customer.Contracts[0].CompanyLicenses[0].CompanyName = customer.Name;
        if (customer.OrgNumber) {
            // orgnr is not required in some environments
            customer.OrgNumber = customer.OrgNumber.replace(/\ /g, '');
            customer.Contracts[0].CompanyLicenses[0].OrgNumber = customer.OrgNumber;
        }

        const token = this.authService.decodedToken();

        customer.Contracts[0].CompanyLicenses[0].UserLicenses = [
            {
                Email: token.email,
                UserName: token.name,
                IsAdmin: true,
            },
        ];

        customer.Managers = [
            {
                AccessRole: 1,
                User: {
                    Email: token.email,
                    Name: token.name,
                },
            },
        ];

        if (this.state().CompanySettings) {
            customer.Contracts[0].CompanyLicenses[0].CompanyDetails = this.state().CompanySettings;
        }

        if (this.hasSelectedEndUserAgreement()) {
            customer.IsBankCustomer = true;
            const agreementBankIdentifier = this.selectCompanyState().selectedEndUserAgreement.BankIdentifier;
            const department = this.authService.publicSettings?.Departments?.find(
                (d) => d.Identifier === agreementBankIdentifier,
            );
            customer.DepartmentID = department?.ID || null;
        }

        return null;
    }

    createCustomer() {
        let errorMessage = this.setPayload();
        if (errorMessage) {
            return throwError(() => new Error(errorMessage));
        }

        this.busyCreatingCompany = true;

        const isEnk = !!this.state().Extras?.isEnk;
        const includeVat = this.state().CompanySettings?.TaxMandatoryType === TaxMandatoryType.TaxMandatory;
        return forkJoin([this.initService.getCompanyTemplate(isEnk, includeVat), this.initService.getCompanies()]).pipe(
            switchMap(([template, companies]) => {
                const customer = this.state().Customer;
                if (template?.Key) {
                    customer.Contracts[0].CompanyLicenses[0].TemplateCompanyKey = template.Key;
                }

                this.existingCompanies = companies || [];

                let endpoint = `/api/customers?isOnboarding=true`;
                if (this.isProspect) {
                    endpoint += '&isProspect=true';
                }

                return this.uniHttp
                    .asPOST()
                    .usingElsaDomain()
                    .withCredentials()
                    .withEndPoint(endpoint)
                    .withBody(customer)
                    .send()
                    .pipe(map((res) => res.body));
            }),
            catchError((err) => {
                this.busyCreatingCompany = false;
                throw err;
            }),
        );
    }

    checkCompanyCreationStatus() {
        const companyCreated = (companies) => {
            if (!companies.length || companies.length === this.existingCompanies.length) {
                return false;
            }
            const nameLowerCase = this.isDemoFlow
                ? this.state().DemoCompanyName.toLowerCase()
                : this.state().Customer.Name.toLowerCase();

            const company = (companies || []).find((c) => {
                // Includes instead of equality check here because the name given
                // to the company will in some cases be 'DEMO <companyname>'
                // also make sure the key doesnt already exist (is this check enough?)
                return (
                    (c.Name || '').toLowerCase().includes(nameLowerCase) &&
                    !this.existingCompanies.some((e) => e.Key === c.Key)
                );
            });
            return company;
        };

        return this.initService.getCompanies().pipe(
            expand((res) => {
                // if company is created, exit this expand, else try again after 3 seconds
                const company = companyCreated(res || []);
                if (company) {
                    this.createdCompany = company;
                    return EMPTY;
                }
                return this.initService.getCompanies().pipe(delay(3000));
            }),
            // filter to make sure subscribers only get a response when companyCreated is truthy
            filter(companyCreated),
            // map so that we only return the newly created company
            map((companies) => companyCreated(companies)),
        );
    }

    sendCompanyCreatedReceipt(companyKey: string) {
        const payload = this.getCompanyCreatedReceiptPayload();
        return this.uniHttp
            .asPOST()
            .withHeader('CompanyKey', companyKey)
            .usingInitDomain()
            .withEndPoint('send-company-created-receipt')
            .withBody(payload)
            .send({}, null, false)
            .pipe(
                map((res) => res.body),
                catchError((err) => {
                    console.error(err);
                    return EMPTY;
                }),
            );
    }

    shouldCreateBankAgreement(): boolean {
        if (!this.state()?.BankServices) return false;
        const bankServices = this.state().BankServices;

        return bankServices.HasPayments || bankServices.HasStatements;
    }

    updateRouteParams() {
        const optionalParams = {};
        this.route.queryParamMap.subscribe((params) => {
            params.keys.forEach((key) => {
                optionalParams[key] = params.get(key);
            });
        });

        optionalParams['step'] = this.currentStepIndex + 1;

        // might wanna change this to > 0, if demo is gonna be handled by companyType=demo
        this.selectedContractType >= 0
            ? (optionalParams['contractType'] = this.selectedContractType)
            : delete optionalParams['contractType'];

        // be aware of typos, param keys are case sensitive

        //console.log(optionalParams);

        const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: optionalParams }).toString();
        this.router.navigateByUrl(url);
    }

    setCurrentStepByType(stepType: ONBOARDING_STEPS) {
        const targetStepIndex = this.steps.findIndex((s) => s.stepType === stepType);
        if (targetStepIndex === -1) {
            this.toastService.addToast(`Can't find step with type: ${stepType}`);
            return;
        }

        this.setCurrentStep(targetStepIndex + 1, true, true);
    }

    setCurrentStep(stepFromQueryParam: number, updateParams = false, skipValidCheck = false) {
        if (!isNaN(stepFromQueryParam) && stepFromQueryParam > 0 && stepFromQueryParam <= this.steps.length) {
            const stepFromQueryParamIndex = stepFromQueryParam - 1;

            if (!skipValidCheck) {
                // go to the earliest invalid step
                const firstInvalidStepIndex = this.steps.findIndex((s) => !s.isValid);
                if (
                    firstInvalidStepIndex !== -1 &&
                    firstInvalidStepIndex !== stepFromQueryParamIndex &&
                    firstInvalidStepIndex < stepFromQueryParamIndex
                ) {
                    this.setCurrentStep(firstInvalidStepIndex, true);
                }
            }

            this.currentStepIndex = stepFromQueryParamIndex;
        } else {
            this.currentStepIndex = 0;
            updateParams = true;
        }

        if (updateParams) {
            this.updateRouteParams();
        }

        this.currentStep = this.steps[this.currentStepIndex];
    }

    setCurrentStepIsValid(isValid: boolean) {
        this.currentStep.isValid = isValid;
        this.storage.setSessionItem(ONBOARDING_STEPS_STORAGE_KEY, this.steps);
    }

    setStepIsValidByType(stepType: ONBOARDING_STEPS, isValid: boolean) {
        const step = this.steps.find((s) => s.stepType === stepType);
        if (step) {
            step.isValid = isValid;
            this.storage.setSessionItem(ONBOARDING_STEPS_STORAGE_KEY, this.steps);
        }
    }

    goNext() {
        // check if race condition, try with setTimeout if yes
        if (!this.currentStep.isValid) return;

        let nextStepIndex = -1;

        if (this.currentStep.nextStep) {
            const nextStepType = this.currentStep.nextStep();
            nextStepIndex = this.steps.findIndex((s) => s.stepType === nextStepType);
        }

        if (nextStepIndex > -1) {
            this.currentStepIndex = nextStepIndex;
        } else {
            this.currentStepIndex++;
        }

        if (this.currentStepIndex < this.steps.length) {
            this.updateRouteParams();
        } else {
            // reset, this should only happen if there's faulty logic or setup, aka a developer error
            this.currentStepIndex = this.steps.findIndex((s) => s.stepType === this.currentStep.stepType);
            if (window.location.href.includes('localhost') || window.location.href.includes('playground')) {
                this.toastService.addToast('There is no next step in the current setup', ToastType.info, 3);
            }
        }
        this.scrollToTop();
    }

    goBack() {
        let previousStepIndex = -1;

        // check if race condition, try with setTimeout if yes
        if (this.currentStep.previousStep) {
            previousStepIndex = this.steps.findIndex((s) => s.stepType === this.currentStep.previousStep());
        }

        if (previousStepIndex > -1) {
            this.currentStepIndex = previousStepIndex;
        } else {
            this.currentStepIndex--;
        }

        if (this.currentStepIndex >= 0 && this.currentStepIndex < this.steps.length) {
            this.updateRouteParams();
        } else {
            // reset, this should only happen if there's faulty logic or setup
            this.currentStepIndex = this.steps.findIndex((s) => s.stepType === this.currentStep.stepType);
            if (window.location.href.includes('localhost') || window.location.href.includes('playground')) {
                this.toastService.addToast('There is no previous step in the current setup', ToastType.info, 3);
            }
        }
        this.scrollToTop();
    }

    hasSelectedEndUserAgreement(): boolean {
        if (!this.selectCompanyState()?.endUserAgreements?.length) return false;

        const selectedOrgNumber = this.state().Customer.OrgNumber;
        const selectedAgreement = this.selectCompanyState().endUserAgreements?.find(
            (a) => a.OrganizationNumber === selectedOrgNumber,
        );

        if (selectedAgreement) {
            this.updateSelectCompanyState('selectedEndUserAgreement', selectedAgreement);
            return true;
        }

        return false;
    }

    searchBrreg(query: string) {
        const orgNumber = query.replace(/\ /g, '');
        const url = this.modulusService.isValidOrgNr(orgNumber)
            ? `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`
            : `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${encodeURI(query)}`;

        return this.http.get(url).pipe(
            map((res) => {
                if (res['_embedded'] && res['_embedded'].enheter) {
                    return res['_embedded'].enheter;
                } else if (res['organisasjonsnummer']) {
                    return [res];
                } else {
                    return [];
                }
            }),
        );
    }

    onBrregCompanySelected(brregCompany, toastIfNull = false) {
        if (!brregCompany) {
            if (toastIfNull) {
                this.toastService.addToast(
                    'Ingen treff',
                    ToastType.info,
                    6,
                    'Fant ingen selskaper med dette organisasjonsnummeret.',
                );
            }

            this.setCustomer(null);
            this.setTaxMandatoryType(TaxMandatoryType.TaxMandatory); // or should it be 'not tax mandatory'?
            this.setExtras({
                ...this.state().Extras,
                skipTaxType: false,
                isEnk: false,
            });
            this.setStepIsValidByType(ONBOARDING_STEPS.TAX_TYPE, false);
            return;
        }

        let data = {};

        if (!get(brregCompany, 'forretningsadresse')) {
            data = {
                CompanyName: brregCompany.navn,
                Address: get(brregCompany, 'postadresse.adresse[0]', ''),
                PostalCode: get(brregCompany, 'postadresse.postnummer'),
                City: get(brregCompany, 'postadresse.poststed'),
                Country: get(brregCompany, 'postadresse.land'),
                CountryCode: get(brregCompany, 'postadresse.landkode'),
                OrganizationNumber: brregCompany.organisasjonsnummer,
            };
        } else {
            data = {
                CompanyName: brregCompany.navn,
                Address: get(brregCompany, 'forretningsadresse.adresse[0]', ''),
                PostalCode: get(brregCompany, 'forretningsadresse.postnummer'),
                City: get(brregCompany, 'forretningsadresse.poststed'),
                Country: get(brregCompany, 'forretningsadresse.land'),
                CountryCode: get(brregCompany, 'forretningsadresse.landkode'),
                OrganizationNumber: brregCompany.organisasjonsnummer,
            };
        }

        this.mapBrregToElsaCustomer(data);

        const isTaxMandatory = brregCompany.registrertIMvaregisteret === true;
        this.setTaxMandatoryType(isTaxMandatory ? TaxMandatoryType.TaxMandatory : TaxMandatoryType.NotTaxMandatory);
        this.setStepIsValidByType(ONBOARDING_STEPS.TAX_TYPE, isTaxMandatory);
        this.setExtras({
            ...this.state().Extras,
            skipTaxType: isTaxMandatory,
            isEnk: get(brregCompany, 'organisasjonsform.kode') === 'ENK',
        });
    }

    mapBrregToElsaCustomer(data: any) {
        const orgNumberNoWhiteSpace = data.OrganizationNumber.replace(/\ /g, '');
        const customer: ElsaCustomerPartial = {
            Name: data.CompanyName,
            OrgNumber: orgNumberNoWhiteSpace,
            Contracts: [
                {
                    CompanyLicenses: [
                        {
                            CompanyName: data.CompanyName,
                            OrgNumber: orgNumberNoWhiteSpace,
                        },
                    ],
                },
            ],
            CustomerAddress: {
                AddressLine1: data.Address,
                PostalCode: data.PostalCode,
                City: data.City,
                Country: data.Country || 'Norge',
            },
        };

        this.setCustomer(customer);
    }

    buildSteps() {
        // if (!this.bankIdFlow) {
        //     this.steps = this.getEmailFlowSteps();
        //     return;
        // }

        // DEVELOPERS: you have to manually delete sessionStorage when modifying any steps
        const stepsInStorage = this.storage.getSessionItem(ONBOARDING_STEPS_STORAGE_KEY);

        switch (this.theme) {
            case THEMES.EIKA:
                this.steps = this.mapEikaStepFunctions(stepsInStorage ?? this.getEikaSteps());
                break;
            case THEMES.EXT02:
                // dnb doesn't need special step functions, just pure go back or go next
                this.steps = stepsInStorage ?? this.getDnbSteps();
                break;
            case THEMES.SR:
                this.steps = this.mapSb1StepFunctions(stepsInStorage ?? this.getSb1Steps());
                break;
            default:
                this.steps = this.getDefaultSteps();
                break;
        }

        // might have to specify this for each environment, but everyone should have it for now
        if (!this.steps.some((step) => step.stepType === ONBOARDING_STEPS.VALIDATION_ERROR)) {
            this.steps.push({
                stepType: ONBOARDING_STEPS.VALIDATION_ERROR,
                hideGoBackButton: true,
                hideGoNextButton: true,
                isValid: true,
            });
        }
    }

    getEikaSteps(): OnboardingStep[] {
        return [
            {
                stepType: ONBOARDING_STEPS.SELECT_CONTRACTTYPE,
                hideGoNextButton: true, // next happens when selecting type
                hideGoBackButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.SELECT_COMPANY,
            },
            {
                stepType: ONBOARDING_STEPS.TAX_TYPE,
            },
            {
                stepType: ONBOARDING_STEPS.BANK_SERVICES,
            },
            {
                stepType: ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT,
                isValid: true, // kinda an optional step, always true
            },
            {
                stepType: ONBOARDING_STEPS.CONFIRM_AND_CREATE,
                goNextLabel: 'Bekreft og opprett bedrift',
                hideGoBackButton: true,
                hideGoNextButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.COMPANY_CREATED,
                hideGoBackButton: true,
                hideGoNextButton: true,
                isValid: true,
            },
        ];
    }

    mapEikaStepFunctions(steps: any[]) {
        const eikaStepFunctions = [
            {
                stepType: ONBOARDING_STEPS.SELECT_COMPANY,
                nextStep: () => {
                    if (!this.hasSelectedEndUserAgreement()) {
                        this.setStepIsValidByType(ONBOARDING_STEPS.BANK_SERVICES, true);
                        return this.state().Extras.skipTaxType
                            ? ONBOARDING_STEPS.CONFIRM_AND_CREATE
                            : ONBOARDING_STEPS.TAX_TYPE;
                    }
                    return this.state().Extras.skipTaxType ? ONBOARDING_STEPS.BANK_SERVICES : ONBOARDING_STEPS.TAX_TYPE;
                },
            },
            {
                stepType: ONBOARDING_STEPS.TAX_TYPE,
                nextStep: () => {
                    return this.hasSelectedEndUserAgreement()
                        ? ONBOARDING_STEPS.BANK_SERVICES
                        : ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                },
            },
            {
                stepType: ONBOARDING_STEPS.BANK_SERVICES,
                nextStep: () => {
                    return this.hasSelectedEndUserAgreement()
                        ? ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT
                        : ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                },
                previousStep: () => {
                    return this.state().Extras.skipTaxType
                        ? ONBOARDING_STEPS.SELECT_COMPANY
                        : ONBOARDING_STEPS.TAX_TYPE;
                },
            },
            {
                stepType: ONBOARDING_STEPS.CONFIRM_AND_CREATE,
                previousStep: () => {
                    if (this.hasSelectedEndUserAgreement()) {
                        return ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT;
                    }
                    return this.state().Extras.skipTaxType
                        ? ONBOARDING_STEPS.SELECT_COMPANY
                        : ONBOARDING_STEPS.TAX_TYPE;
                },
            },
        ];

        return steps.map((step) => {
            const currentFunctionStep = eikaStepFunctions.find((fstep) => fstep.stepType === step.stepType);

            return currentFunctionStep ? { ...step, ...currentFunctionStep } : step;
        });
    }

    getSb1Steps(): OnboardingStep[] {
        return [
            {
                stepType: ONBOARDING_STEPS.SELECT_CONTRACTTYPE,
                hideGoNextButton: true, // next happens when selecting type
                hideGoBackButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.SELECT_COMPANY,
            },
            {
                stepType: ONBOARDING_STEPS.BANK_SERVICES,
            },
            {
                stepType: ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT,
                isValid: true,
            },
            {
                stepType: ONBOARDING_STEPS.TAX_TYPE,
            },
            {
                stepType: ONBOARDING_STEPS.EXISTING_RECONCILIATION,
            },
            {
                stepType: ONBOARDING_STEPS.BANK_ACCOUNTS,
                hideGoBackButton: true,
                hideGoNextButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.CONFIRM_AND_CREATE,
                goNextLabel: 'Aktiver kundeforhold',
                hideGoBackButton: true,
                hideGoNextButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.COMPANY_CREATED,
                hideGoBackButton: true,
                hideGoNextButton: true,
                isValid: true,
            },
        ];
    }

    mapSb1StepFunctions(steps: any[]) {
        const sb1StepFunctions = [
            {
                stepType: ONBOARDING_STEPS.SELECT_COMPANY,
                nextStep: () => {
                    if (!this.hasSelectedEndUserAgreement()) {
                        this.setStepIsValidByType(ONBOARDING_STEPS.BANK_SERVICES, true);
                        this.setStepIsValidByType(ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT, true);
                        this.setStepIsValidByType(ONBOARDING_STEPS.EXISTING_RECONCILIATION, true);
                        this.setStepIsValidByType(ONBOARDING_STEPS.BANK_ACCOUNTS, true);
                        return this.state().Extras.skipTaxType
                            ? ONBOARDING_STEPS.CONFIRM_AND_CREATE
                            : ONBOARDING_STEPS.TAX_TYPE;
                    }
                    return ONBOARDING_STEPS.BANK_SERVICES;
                },
            },
            {
                stepType: ONBOARDING_STEPS.BANK_SERVICES,
                nextStep: () => {
                    return ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT;
                },
                previousStep: () => {
                    return ONBOARDING_STEPS.SELECT_COMPANY;
                },
            },
            {
                stepType: ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT,
                nextStep: () => {
                    return this.state().Extras.skipTaxType
                        ? ONBOARDING_STEPS.EXISTING_RECONCILIATION
                        : ONBOARDING_STEPS.TAX_TYPE;
                },
                previousStep: () => {
                    return ONBOARDING_STEPS.BANK_SERVICES;
                },
            },
            {
                stepType: ONBOARDING_STEPS.TAX_TYPE,
                nextStep: () => {
                    return this.hasSelectedEndUserAgreement()
                        ? ONBOARDING_STEPS.EXISTING_RECONCILIATION
                        : ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                },
                previousStep: () => {
                    return this.hasSelectedEndUserAgreement()
                        ? ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT
                        : ONBOARDING_STEPS.SELECT_COMPANY;
                },
            },
            {
                stepType: ONBOARDING_STEPS.EXISTING_RECONCILIATION,
                nextStep: () => {
                    return this.state().Extras.hasExistingReconciliation &&
                        this.bankAccountsState().bankAccounts?.length
                        ? ONBOARDING_STEPS.BANK_ACCOUNTS
                        : ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                },
                previousStep: () => {
                    return this.state().Extras.skipTaxType
                        ? ONBOARDING_STEPS.DEFAULT_BANK_ACCOUNT
                        : ONBOARDING_STEPS.TAX_TYPE;
                },
            },
            {
                stepType: ONBOARDING_STEPS.BANK_ACCOUNTS,
                nextStep: () => {
                    return ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                },
                previousStep: () => {
                    return ONBOARDING_STEPS.EXISTING_RECONCILIATION;
                },
            },
            {
                stepType: ONBOARDING_STEPS.CONFIRM_AND_CREATE,
                previousStep: () => {
                    if (this.hasSelectedEndUserAgreement()) {
                        const reconciliationExists = this.state().Extras.hasExistingReconciliation;
                        const hasVisitedBankAccountsStep =
                            sessionStorage.getItem('HAS_VISITED_BANK_ACCOUNTS') === 'true';
                        const hasBankAccounts = this.bankAccountsState().bankAccounts?.length > 0;

                        return (reconciliationExists || hasVisitedBankAccountsStep) && hasBankAccounts
                            ? ONBOARDING_STEPS.BANK_ACCOUNTS
                            : ONBOARDING_STEPS.EXISTING_RECONCILIATION;
                    }
                    return this.state().Extras.skipTaxType
                        ? ONBOARDING_STEPS.SELECT_COMPANY
                        : ONBOARDING_STEPS.TAX_TYPE;
                },
            },
        ];

        return steps.map((step) => {
            const currentFunctionStep = sb1StepFunctions.find((fstep) => fstep.stepType === step.stepType);

            return currentFunctionStep ? { ...step, ...currentFunctionStep } : step;
        });
    }

    getDnbSteps(): OnboardingStep[] {
        return [
            {
                stepType: ONBOARDING_STEPS.SELECT_CONTRACTTYPE,
                hideGoNextButton: true, // next happens when selecting type
                hideGoBackButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.SELECT_COMPANY,
            },
            {
                stepType: ONBOARDING_STEPS.CONFIRM_AND_CREATE,
            },
            {
                stepType: ONBOARDING_STEPS.BANK_SERVICES,
                hideGoBackButton: true,
                hideGoNextButton: true,
                isValid: true,
            },
        ];
    }

    getDefaultSteps(): OnboardingStep[] {
        return [
            {
                stepType: ONBOARDING_STEPS.SELECT_CONTRACTTYPE,
            },
        ];
    }

    getEmailFlowSteps(): OnboardingStep[] {
        return [
            {
                stepType: ONBOARDING_STEPS.COMPANY_INFORMATION,
                hideGoBackButton: true,
            },
            {
                stepType: ONBOARDING_STEPS.CONTRACT_ACTIVATION,
                hideGoNextButton: true,
            },
        ];
    }

    getDemoCustomerPayload(user: any, templateKey?: string): any {
        const demoCompanyName = `DEMO ${rigDate().format('YYYY.MM.DD HH:mm')}`;
        this.setDemoCompanyName(demoCompanyName);

        return {
            Name: user.name,
            DepartmentID: null,
            OrgNumber: null,
            ContactPerson: user.name,
            ContactEmail: user.email,
            ContactPhone: '',
            Managers: [
                {
                    User: {
                        Name: user.name,
                        Email: user.email,
                    },
                    AccessRole: 1,
                },
            ],
            Contracts: [
                {
                    ContractType: 0,
                    CompanyLicenses: [
                        {
                            CompanyName: demoCompanyName,
                            OrgNumber: null,
                            TemplateCompanyKey: templateKey,
                            UserLicenses: [
                                {
                                    UserName: user.name,
                                    Email: user.email,
                                    IsAdmin: true,
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    }

    deactivateDemoCompany(id: number, body: any): Observable<any> {
        return this.http.put(`${this.ELSA_SERVER_URL}/api/companylicenses/${id}`, body);
    }

    getCompanyBankAccountsPayload(): CompanyBankAccountDto[] {
        if (!this.bankAccountsState()?.bankAccounts?.length) return [];
        const payload: CompanyBankAccountDto[] = [];
        this.bankAccountsState().bankAccounts.forEach((account) => {
            const isDefaultAccount =
                account.AccountNumber === this.bankAccountsState().selectedDefaultBankAccount?.AccountNumber;
            const isBankStatement = !!this.state().BankServices?.HasStatements;
            const isBankBalance = isBankStatement;
            const isOutgoing = !!this.state().BankServices?.HasPayments;
            payload.push({
                BbanOrIban: account.AccountNumber,
                AccountType: account.BankAccountType,
                IsBankStatement: isBankStatement,
                IsBankBalance: isBankBalance,
                IsOutgoing: isOutgoing,
                AgreementID: null,
                MainAccountNumber: account.Account?.AccountNumber || null,
                Label: account.Label,
                SetAsDefaultCompanyBankAccount: isDefaultAccount,
            });
        });

        return payload;
    }

    getCompanyCreatedReceiptPayload(): CompanyCreatedReceipt {
        const payload: CompanyCreatedReceipt = {
            Theme: theme.theme,
            AppName: theme.appName,
            CompanyName: this.state().Customer.Name,
            ToEmail: this.authService.decodedToken().email,
            BankAccounts: this.bankAccountsState()?.bankAccounts || [],
        };

        const bankServices = this.state().BankServices;
        if (bankServices) {
            payload.HasPayments = bankServices.HasPayments;
            payload.HasStatements = bankServices.HasStatements;
            payload.HasPreApprovedPayment = bankServices.HasPreApprovedPayment;
            payload.BankStatementStartDate = bankServices.BankStatementStartDate;
        }

        return payload;
    }

    scrollToTop() {
        const contentContainer = document.getElementsByTagName('uni-init');
        if (contentContainer && contentContainer[0]) {
            contentContainer[0].scrollTop = 0;
        }
    }
}
